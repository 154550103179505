
const genericStrings = {
    username: "Username",
    password: "Password",
    org_id: "Organization ID",
    edit: "Edit",
    save: "Save",
    cancel: "Cancel",
    create: "Create",
    search: "Search",
    download: "Download",
    downloadX: "Download X",
    downloadZ: "Download Z",
    from: "From",
    to: "To",
    total: "Total",
    paid: "Paid",
    vat: "VAT",
    user: "User",
    comment: "Comment",
    price: "Price",
    accountId: "Account ID",
    name: "Name",
    id: "Id",
    actions: "Actions",
    refresh: "Refresh",
    account: "Account",
    status: "Status",
    openedAt: "Open At",
    open: "Open",
    close: "Close",
    tableNumber: "Table",
    delete: "Delete",
    takeaway: "Takeaway",
    deleteConfirm: "Are you sure you want to delete this ?<br> This action cannot be undone !",
    date: "Date",
    filter: "Filter",
    ipAddress: "IP Address",
    yes: "Yes",
    no: "No",
    all: "All",
    changePayType: (ctx) => `Change payment type of ${ctx.named('of')} from ${ctx.named('from')} to ${ctx.named('to')} ? `,
    tables: "Tables",
    delivery: "Delivery",
    shift: "Shift",
    openAccounts: "Open Accounts",
    lastShift: "Last Shift",
    dailySales: "Daily Sales",
    rushHours: "Rush Hours",
    dashboardIntervalChoice: 'Selected Interval',
    dashboardIntervalCurrentShift: 'Current Shift',
    dashboardIntervalLastShift: 'Last Shift',
    dashboardIntervalToday: 'Today',
    dashboardIntervalYesterday: 'Yesterday',
    dashboardIntervalThisWeek: 'This Week',
    dashboardIntervalLastWeek: 'Last Week',
    dashboardIntervalThisMonth: 'This Month',
    dashboardIntervalLastMonth: 'Last Month',
    clients: 'Clients',
    invoice: 'Invoice',
    mail: 'Email',
    phone: 'Phone',
    address: 'Address',
    street: 'Street',
    city: 'City'
}

const navBarStrings = {
    home: "Home",
    users: "Users",
    products: "Products",
    groups: "Groups",
    tables: "Tables",
    my_account: "My Account",
    settings: "Settings",
    dashboard: "Dashboard",
    analytics: "Analytics",
    account: "Account",
    acitivity: "Acitivity",
    order_history: "Order History",
    account_history: "Accounts",
    shifts: "Shifts",
    shift: "Shift",
    payments: "Payments",
    log: "Log",
    logout: "Logout",
    printers: "Printers",
    stock: "Stock",
    clients: "clients",
    invoice: "Invoices"
}

const toastStrings = {
    successSave: "Save success",
    errorSave: "Save error",
    successEdit: "Edit success",
    errorEdit: "Edit error",
    createSuccess: "Create success",
    createError: "Create error",
    deleteSuccess: "Delete success",
    deleteError: "Delete error",
    duplicateSuccess: "Duplicate success",
    duplicateError: "Duplicate error",
};

const loginPageStrings = {
    welcome_back: "Welcome Back",
    login_page_subtitle: "Your perfect restaurant companion always there when you need it",
    login_page_form_title: "Log Back In",
    login_page_form_subtitle: "Enter your login details",
    login_button: "Login"
}

const dashboardStrings = {
    yoursInfos: "Your establishment",
    empty: "No table is occupied",
    shiftTitle: "Open table",
    openTableText: "Open table",
    takeawayText: "Open takeaway",
    noshiftOpen: "No shift opened",
    takeawayTitle: "Takeaway",
    currentShift: "Current shift",
    latestShift: "Last shift",
    lastShift: "Last shift",
    accountsTotal: "Total accounts",
    accountsTooltip: 'Total number of open AND closed accounts, with at least 1 paid item in the account.',
    remainingUnpaid: "Remaining Un-Paid",
    remainingUnpaidTooltip: "The total amount of ordered items that still need to be paid fully or partially",
    salesPaid: "Sales Paid",
    salesPaidTooltip: "The total amount of paid items in the accounts that are part of the selected shift(s)",
    averageBasket: "Average Basket",

};

const userPageStrings = {
    username: "Username",
    password: "Password",
    type: "User Type",
    pincode: "Register pin code",
    vwTerminalIp: "VW Terminal IP",
    userPrinter: "User Printer",
    selectAbilities: "Select Abilities",
    pincodeTooltip: "The pin code required to unlock the app when pin lock is enabled. This will also allow tracking user actions based on the entered pincode on the main register.",
    userPrinterTooltip: "The printer that will be used for this user. This will override the main printer if set and will allow VAT/Summary tickets to be printed on this printer",
}

const productGroupPageStrings = {
    productGroups: "Product Groups",
    addGroup: "Add Group",
    vatIn: "Vat In",
    vatOut: "Vat Out",
    printerIp: "Printer Ip Address",
    name: "Name",
    userHidden: "Hide on app?",
    webHidden: "Hide on website?",
    isHidden: "Hidden in app",
    isNotHidden: "Shown in app",
    KitchenPrintEnabled: "Print",
    noFundResultTable: "No customers found.",
    loadingResultTable: "Loading customers data. Please wait.",
    color: "Colors",
    vatTooltip: "A VAT that will be suggested when this group is assigned to a product. Changing this will not change VATs of products in this group",
    orderToolltip: "Order of the group in the list, this will be used to sort the groups by ascending order",
    kitchenPrintEnabledTooltip: "Enable/Disable the printing of orders on the assigned printer for products of this group",
    userHiddenTooltip: "Hide this group from the user interface preventing direct orders of products from this group, but still allow it to be used for product paid/free options",
    maxChoicePaid: "Max paid in sub group",
    maxChoiceFree: "Max free in sub group",
    maxChoicePaidToolTip: "When attaching this group to a product you can add a number of maximum paid product to select in the flow.",
    maxChoiceFreeToolTip: "When attaching this group to a product you can add a number of maximum free product to select in the flow."
}

const productPageStrings = {
    name: "Product Name",
    products: "Products",
    addProduct: "Add Product",
    priceVatInclIn: "Price In",
    priceVatInclOut: "Price Out",
    vatIn: "Vat In",
    vatOut: "Vat Out",
    codeBarre: "Barcode",
    description: "Description",
    stock: "Stock",
    productGroup: "Product Group",
    productGroupParent: "Group Parent",
    productGroupOptions: "Product Group Options Free",
    productGroupOptionsPaid: "Product Group Options Paid",
    productFilterByCategory: "Filter by category",
    timedproduct: "Timed product?",
    allowDecimal: "Allow decimal units?",
    dynamicPrice: "Dynamic price?",
    multiChoice: "Multi choice allowed?",
    orderIndex: "Order",
    weightPrice: "Weight Price?",
    visibility: "Visibility",
    visibilityWeb: "Web Visibility",
    userHiddenTooltip: "Hide this product from the user interface preventing direct orders of this product, but still allow it to be used for product paid/free options",
    imageUrl: "Image URL in https",
    color: "Color",
    orderTooltip: "Order of the product in the list, this will be used to sort the products of the same group by ascending order",
    timedProductTooltip: "When enabled, this product will start a timer when ordered and on close will use the product price as a price/minute to compute the total price",
    allowDecimalTooltip: "Will allow the user to enter a decimal value for the quantity of the product on long click before ordering or before sending by selecting product > units",
    dynamicPriceTooltip: "This will ignore the above price and require the user to enter a price when this product is selected",
    weightPriceTooltip: "This will require the user to enter a weight in KG when this product is selected. It will detect the weight automatically if a scale is connected",
    promo_percent: "Fixed promotion in %",
    webHiddenTooltip: "Hide this group from the web interface preventing direct orders of products from this group, but still allow it to be used for product paid/free options"

}

const stockPageStrings = {
    name: "Product Name",
    stock: "Stock",
    productGroup: "Product Group",
    productGroupOptions: "Product Group Options Free",
    productGroupOptionsPaid: "Product Group Options Paid",
    productFilterByCategory: "Filter by category",
    timedproduct: "Timed product?",
    allowDecimal: "Allow decimal?"
}

const tableStrings = {
    name: "Tables",
    addTable: "Add Table",
    nameZones: "Zones",
    selectZone: "Select zones",
    addZones: "Add Zones",
    nameTypes: "Types",
    selectType: "Select types",
    addTypes: "Add Types",
    tableNumber: "Table Number",
    zoneNumber: "Zone Number",
    zoneName: "Zone Name",
    typeName: "Type Name",
    zoneEditTitle: "Create/Edit Zone",
}

const paymentsStrings = {
    header: "Payment Transactions",
    orderId: "Order ID",
    paymentType: "Type",
    amount: "Amount",
    returned: "Returned",
    totalCash: "Total Cash",
    totalCard: "Total Card",
    totalOffered: "Total Offered",
    totalError: "Total Error",
    totalVatExcl: "HVAT",
    totalVatIncl: "Total",
    vatValue: "Value",
    totalBankTransfer: "Total Transfer"
}

const orderedProductsString = {
    header: "Order History",
    accountId: "Account ID",
    linkedTo: "Linked To"
}

const accountHistory = {
    header: "Accounts History"
}

const shiftDetail = {
    header: "Shift detail"
}

const shiftStat = {
    header: "Shift statistic"
}

const shift = {
    openShift: "Open time",
    openUserName: "Open user",
    openUserTime: "Open time",
    openUserCash: "Start cash",
    closeUserName: "Close user",
    closeUserTime: "Close time ",
    closeUserCash: "End cash",
    totalCash: "Cash",
    totalCard: "Card",
    totalOffered: "Offered",
    totalBankTransfer: "B. Transfer",
    total: "Total shift",
    inProgress: "In progress...",
    endComment: "Any comment about the end shift",
    paymentType: "Force to close the account with mixed payment by",
    totalCashClose: "Your total amount of cash",
    HeaderShiftClose: "Close this shift",
    MessageCloseShift: "Are you sure you want to end the shift? You can't open this shift afterwards !",
    MessageOpenShift: "Are you sure you want to open a new shift?",
    OpenNewShift: "Open a new shift?",
    OpenNewShiftButton: "Open a shift"
};

const printerStrings = {
    printers: "Printers",
    addPrinter: "Add printer",
    printerName: "Printer name",
    doublePrint: "Print double"
}

const settingsPageStrings = {
    name: "Your Organisation",
    organisationId: "Organisation code",
    organisationName: "Name",
    vatNumber: "VAT number",
    address: "Address",
    telephone: "Telephone",
    contactEmail: "Contact email",
    website: "Website",
    mainPrinterIpAddress: "Main printer IP Address",
    takeawaySectionEnabled: "Takeaway section enabled",
    tableSectionEnabled: "Table section enabled",
    shiftEnabled: "Shift enabled",
    printerSettings: "Printer settings",
    webSettings: "Web order settings",
    superSettings: "Super settings",
    kitchenTopSpacing: "Kitchen top spacing",
    kitchenGroupItems: "Kitchen group items",
    charactersPerLine: "Characters per line",
    pinLockTimeout: "Pin lock timeout (seconde)",
    pinLockEnabled: "Pin lock",
    kitchenPrintEnabled: "Print order ticket",
    autoCloseAccountOnPaymentEnabled: "Close account after payment",
    autoPrintSummaryTicketEnabled: "Auto print ticket after payment",
    autoPrintShiftXY: "Auto print X/Z",
    hideShiftInfo: "Hide shift info",
    paymentTypeOfferedEnabled: "Allow offer",
    paymentTypeTransferEnabled: "Allow bank transfer",
    sendOrderBehavior: "Action After send",
    autoOpenCashRegister: "Open cash register automatically",
    DEFAULT: "DEFAULT",
    NAV_BACK: "RETURN",
    NAV_PAYMENT: "GO PAYMENT",
    orgaFlag: "App Settings",
    refundEnabled: "Allow Refund",
    addItemsToTop: "New product on top",
    autoOpenNewAccount: "Auto open new account",
    normalAccountOpenButton: "Separate in Delivery/Takeaway",
    vwIsvAmount: "ISV Amount",
    vwEnabled: "VW Enabled",
    vwTerminalMerchantId: "ISV Merchant ID",
    vwTerminalId: "Terminal ID (For NEXT terminals)",
    mapsAutoCompleteEnabled: "Google Maps Integration",
    autoAssignDeviceToMainType: "Auto assign device to main type",
    pinLockTooltip: "Require a pin code to access the app",
    shiftsTooltip: "Enable the shift system, this will require the user to open a shift before using the app and close it at the end of each shift",
    printOrderTicketTooltip: "Enable the printing of a ticket when an order is sent to the kitchen",
    closeAccountAfterPaymentTooltip: "Automatically close the account after a payment",
    autoPrintSummaryTicketTooltip: "Automatically print a summary ticket after a payment",
    allowOfferTooltip: "Enables the offer button on the payment screen for ALL users",
    actionAfterSendTooltip: `Choose what happens after an order is sent to the kitchen : 
 - DEFAULT: Stay on the order screen 
 - RETURN: Go back to the order screen 
 - GO PAYMENT: Go to the payment screen`,
    openCashRegisterTooltip: "Automatically open the cash register when a payment is made in cash upon payment confirmation",
    autoPrintShiftXZTooltip: "Automatically print the X/Z on the printer when the shift is closed in the application",
    hideShiftInfoTooltip: "Hide the shift information such as the payment type and the total amount of the shift on the shift management page in the application",
    allowBankTransferTooltip: "Enable the bank transfer payment method on the payment screen for ALL users",
    allowRefundTooltip: "Enable the refund button in an account from the history for ALL users",
    newProductOnTopTooltip: "When enabled, a product is added to an account/table it will appear at the top of the list instead of the bottom",
    autoOpenNewAccountTooltip: "Automatically open a new account when the last one is closed. This will allow for direct order taking after the previous account is closed.",
    separateInDeliveryTakeawayTooltip: `Enabled : this will provide 2 types of account open buttons on the home screen, delivery/takeaway and table.
     Disabled : This will provide only a '+' button to open a new account`,
    subSectionPayment: "Payment",
    subSectionHome: "Home Screen",
    subSectionShift: "Shift",
    subSectionOrdering: "Ordering",
    subSectionWebsite: "Website",
    mainPrinterTooltip: "The main printer ip address where all tickets will be printed by default",
    pinLockTimeoutTooltip: "The time in seconds before the pin lock is activated. This will only work if the pin lock flag is enabled",
    includeParentProductsHint: "Include parent products",
    includeParentProductsTooltip: "Includes products of the parent organizations ONLY on the app if this organization has a parent",
    parentOrgId: "Parent organization ID",
    onlineOrderWebPageEnabled: "Online order activated",
    onlineOrderOpen: "Online order open",
    onlineOrderDeliveryEnabled: "Delivery enabled",
    payOnlineEnabled: "Online payment enabled",
    payAtPickupEnabled: "Pay after order (at pickup) enabled",
    onlineOrderHideNoStockProducts: "Show product with stock available only",
    logoImageUrl: "Link of your logo image",
    bannerImageUrl: "Link of your banner image",
    hideProductImagesInApp: "Hide pictures on APP",
    hideProductImagesInAppTooltip: "If you have placed images on your products, this allows them not to be displayed on the posbel application, but to keep the images visible for your QR-CODE/KIOSK/..."
};

export default {
    generic: genericStrings,
    navBar: navBarStrings,
    toast: toastStrings,
    dashboard: dashboardStrings,
    loginPage: loginPageStrings,
    userPage: userPageStrings,
    productGroupPage: productGroupPageStrings,
    productPage: productPageStrings,
    stockPage: stockPageStrings,
    tablePage: tableStrings,
    paymentsPage: paymentsStrings,
    orderedProductsPage: orderedProductsString,
    accountHistoryPage: accountHistory,
    shiftDetailPage: shiftDetail,
    shiftStatPage: shiftStat,
    shiftPage: shift,
    printerStrings: printerStrings,
    settingsPage: settingsPageStrings,
};
