import apiClient from '../helpers/ApiClient'
import { handleError, authHeader } from '@/helpers/ApiClient';

export const invoiceService = {
    getInvoices, getDownloadInvoice
}

function getInvoices(startDateString, endDateString)
{

    let startMs = new Date(startDateString).getTime();
    let endMs = new Date(endDateString).getTime();

    return apiClient.get("/invoice", {
            params: {startDate: startMs, endDate: endMs},
            headers: authHeader()
        }
    )
        .then((response) => {
            return response.data
        })
        .catch((error) => handleError(error));
}

function getDownloadInvoice(invoiceId) {
    return apiClient
        .get(`/invoice/${invoiceId}/download`,
            {
                headers: authHeader(),
            })
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}