<template>
  <div>
    <page-header
        iconName="inbox.svg"
        :headerTitle="$t('generic.invoice')"
    />


    <DataTable
        :value="invoiceData"
        v-model:filters="filters"
        filter-display="row"
        paginator
        ref="dtInvoices"
        :rows="500"
        :rows-per-page-options="[500, 1000, 2500, 5000, 10000]"
        table-style="min-width: 50rem"
        :loading="loading"
        :global-filter-fields="['invoiceNumber','totalAmount','invoiceDate','clientAccountId', 'clientData.name', 'clientData.email', 'clientData.phoneNumber','clientData.city','clientData.phoneNumber']"
    >
      <template #header>
        <div class="d-flex" style="margin-top: 22px">
          <div class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
          </div>
          <div class="ml-3">
            <Calendar dateFormat="dd-mm-yy" class="mr-1 mb-1" v-model="dates" selectionMode="range" :manualInput="false" showIcon iconDisplay="input" placeholder="jj/mm/aaaa - jj/mm/aaaa" />
            <button class="action-button mr-1" v-if="dates" @click="searchDateCalendar()">
              <inline-svg class="action-icon" :src="require('@/assets/icons/search.svg')" />
            </button>
            <button class="action-button" v-if="dates" @click="deleteDateCalendar()">
              <inline-svg class="action-icon" :src="require('@/assets/icons/close-2.svg')" />
            </button>
          </div>
        </div>
      </template>
      <template #empty> {{ $t('productGroupPage.noFundResultTable') }}</template>
      <template #loading> {{ $t('productGroupPage.loadingResultTable') }}</template>
      <Column field="invoiceNumber" header="Invoice number" sortable></Column>
      <Column field="totalAmount" header="Total" sortable>
        <template #body="{ data }">
          <span v-if="data?.totalAmount">€{{ data?.totalAmount.toFixed(2) }}</span>
          <span v-else>€{{ 0.0.toFixed(2) }}</span>
        </template>
      </Column>
      <Column field="invoiceDate" header="Date" sortable>
        <template #body="{ data }">
          {{ data?.invoiceDate.toDate() }} <br>
          <span class="default-text">{{ data?.invoiceDate.toTime() }}</span>
        </template>
      </Column>
      <Column field="clientAccountId" header="Linked to ticket" sortable>
        <template #body="{ data }">
          <button class="bread-crumb-base" title="stock" @click="openAccountClient(data.clientAccountId)">{{
              data.clientAccountId
            }}
          </button>
        </template>
      </Column>
      <Column field="clientData" header="Client" sortable>
        <template #body="{ data }">
          {{ data.clientData.name }} <br/> {{ data.clientData.email }} <br/> {{ data.clientData.phoneNumber }}  <br>
          {{ data.clientData.zipCode }} {{ data.clientData.city }} - {{ data.clientData.street }} {{ data.clientData.number }} <br/> {{ data.clientData.vat }}
        </template>
      </Column>
      <Column field="actions" header="Actions" sortable>
        <template #body="{ data }">
          <div class="edit-row-btn">
            <button class="" @click="downloadInvoice(data.invoiceId)">
              <inline-svg :src="require('@/assets/icons/download.svg')"/>
            </button>
          </div>
        </template>
      </Column>
    </DataTable>


  </div>
</template>

<script>
import {ref} from 'vue';
import {mapActions, mapState} from "vuex";
import {FilterMatchMode} from 'primevue/api';
import PageHeader from './components/PageHeader.vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';


export default {
  name: "InvoicePage",
  components: {
    PageHeader,
    DataTable,
    Column,
    InputText,
    Calendar
  },
  data() {
    return {
      filters: ref({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        name: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'productGroup.name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
      }),
      selectedFilterProduct: "",
      itemFiltered: [],
      currentSelect: {},
      dates: null
    }
  },
  methods: {
    ...mapActions('invoice', ['getInvoiceData', 'getDownloadInvoice']),

    exportCSV() {
      this.$refs.dtInvoices.exportCSV();
    },
    downloadInvoice(invoice) {
      this.getDownloadInvoice(invoice);
    },
    searchDateCalendar() {
      if (this.dates[0] && this.dates[1]) {
        const startDate = new Date(this.dates[0]);
        const endDate = new Date(this.dates[1]);
        this.getInvoiceData({startDate: (startDate - 7 * 24 * 60 * 60 * 1000), endDate: (endDate + 2 * 24 * 60 * 60 * 1000)});
      } else if (this.dates[0] && !this.dates[1]) {
        const startDate = new Date(this.dates[0]);
        const endDate = new Date(this.dates[0]);
        endDate.setDate(endDate.getDate() + 1);
        this.getInvoiceData({startDate: (startDate - 7 * 24 * 60 * 60 * 1000), endDate: (endDate + 2 * 24 * 60 * 60 * 1000)});
      }
    },
    openAccountClient(accountId) {
      this.$router.push({name: 'AccountSummary', params: {accountId: accountId}});
    },
  },
  computed: {
    ...mapState('invoice', ['invoiceData'])
  },
  mounted() {
    this.getInvoiceData({startDate: 0, endDate: 0});
  }
}
</script>

<style lang="scss" scoped>
button {
  &.action-button {
    background-color: $color-primary-blue;
    height: 41px;
  }
}

.card {
  border-radius: $radius-small;
  background-color: $color-input-back;
  margin-top: $default-edge-spacing-xxl;
  padding: $default-edge-spacing-xl !important;
}

.total-container {
  .header {
    font-size: $font-title-big;
    font-weight: 600;
    padding-left: 10px;
    border-left: 3px solid $color-primary-blue;
  }

  .float-left {
    color: $color-primary-text;
  }
  .float-right {
    color: $color-secondary-text;
  }
}

hr {
  border-color: $color-secondary-text;
  border-radius: 15px;
  opacity: 0.15;
}

.d-card {
  display: flex;
  flex-direction: column
}

</style>